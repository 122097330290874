@use './brandfolder-theme';
@use './smartsheet-theme';
@use 'sass:string';

$themes: (
  brandfolder: brandfolder-theme.$theme,
  smartsheet: smartsheet-theme.$theme,
);

@mixin themify() {
  @each $theme, $map in $themes {
    .theme-#{$theme} &, & .theme-#{$theme} {
      $theme-map: () !global;

      @each $component, $component-props in $map {
        $theme-map: map-merge(
          $theme-map,
          (
            $component: $component-props,
          )
        ) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($component, $key) {
  @return map-get(map-get($theme-map, $component), string.quote($key));
}
