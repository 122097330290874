@use './themes';
@use 'assets/fonts/inter/Inter';

$circularFontURL: 'https://fonts.brandfolder.com/circular/';

@font-face {
  font-family: 'Circular-Bold';
  font-display: swap;
  src: url($circularFontURL + 'lineto-circular-bold.eot');
  src: url($circularFontURL + 'lineto-circular-bold.eot?#iefix') format('embedded-opentype'),
    url($circularFontURL + 'lineto-circular-bold.woff2') format('woff2'),
    url($circularFontURL + 'lineto-circular-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Circular-Book';
  font-display: swap;
  src: url($circularFontURL + 'lineto-circular-book.eot');
  src: url($circularFontURL + 'lineto-circular-book.eot?#iefix') format('embedded-opentype'),
    url($circularFontURL + 'lineto-circular-book.woff2') format('woff2'),
    url($circularFontURL + 'lineto-circular-book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Circular-Medium';
  font-display: swap;
  src: url($circularFontURL + 'lineto-circular-medium.eot');
  src: url($circularFontURL + 'lineto-circular-medium.eot?#iefix') format('embedded-opentype'),
    url($circularFontURL + 'lineto-circular-medium.woff2') format('woff2'),
    url($circularFontURL + 'lineto-circular-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Circular-Italic';
  font-display: swap;
  src: url($circularFontURL + 'lineto-circular-bookitalic.eot');
  src: url($circularFontURL + 'lineto-circular-bookitalic.eot?#iefix') format('embedded-opentype'),
    url($circularFontURL + 'lineto-circular-bookitalic.woff2') format('woff2'),
    url($circularFontURL + 'lineto-circular-bookitalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Circular-Italic-Medium';
  font-display: swap;
  src: url($circularFontURL + 'lineto-circular-mediumitalic.eot');
  src: url($circularFontURL + 'lineto-circular-mediumitalic.eot?#iefix') format('embedded-opentype'),
    url($circularFontURL + 'lineto-circular-mediumitalic.woff2') format('woff2'),
    url($circularFontURL + 'lineto-circular-mediumitalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Circular-Black';
  font-display: swap;
  src: url($circularFontURL + 'lineto-circular-black.eot');
  src: url($circularFontURL + 'lineto-circular-black.eot?#iefix') format('embedded-opentype'),
    url($circularFontURL + 'lineto-circular-black.woff2') format('woff2'),
    url($circularFontURL + 'lineto-circular-black.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@mixin link-base {
  font-family: themes.themed('link', 'font-family');
  text-decoration: underline 1px transparent;
  transition: text-decoration-color 0.3s ease;
  font-style: normal;
  font-weight: themes.themed('link', 'font-weight');
  font-size: themes.themed('link', 'font-size');
  line-height: themes.themed('link', 'line-height');
}

body {
  -webkit-font-smoothing: antialiased;

  select,
  input {
    &[disabled],
    &:disabled {
      background-color: #f5f5f5;
      color: #9b9b9b !important;
    }

    &:invalid {
      color: #9b9b9b !important;
    }
  }

  .title {
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 30px;
  }

  @include themes.themify() {
    color: themes.themed('base-typography', 'color');
    font-family: themes.themed('base-typography', 'font-family');
    font-size: themes.themed('base-typography', 'font-size');
    font-style: themes.themed('base-typography', 'font-style');
    font-weight: themes.themed('base-typography', 'font-weight');
    line-height: themes.themed('base-typography', 'line-height');

    h1 {
      color: themes.themed('header-1', 'color');
      font-family: themes.themed('header-1', 'font-family');
      font-size: themes.themed('header-1', 'font-size');
      font-style: themes.themed('header-1', 'font-style');
      font-weight: themes.themed('header-1', 'font-weight');
      line-height: themes.themed('header-1', 'line-height');
    }

    h2 {
      color: themes.themed('header-2', 'color');
      font-family: themes.themed('header-2', 'font-family');
      font-size: themes.themed('header-2', 'font-size');
      font-style: themes.themed('header-2', 'font-style');
      font-weight: themes.themed('header-2', 'font-weight');
      line-height: themes.themed('header-2', 'line-height');
    }

    h3 {
      color: themes.themed('header-3', 'color');
      font-family: themes.themed('header-3', 'font-family');
      font-size: themes.themed('header-3', 'font-size');
      font-style: themes.themed('header-3', 'font-style');
      font-weight: themes.themed('header-3', 'font-weight');
      line-height: themes.themed('header-3', 'line-height');
    }

    label {
      font-family: themes.themed('font-medium', 'font-family');
      font-style: themes.themed('font-medium', 'font-style');
      font-weight: themes.themed('font-medium', 'font-weight');
    }

    select {
      font-size: themes.themed('select', 'font-size');
      line-height: themes.themed('select', 'line-height');
    }

    .font-medium {
      font-family: themes.themed('font-medium', 'font-family');
      font-style: themes.themed('font-medium', 'font-style');
      font-weight: themes.themed('font-medium', 'font-weight');
    }

    .font-semi-bold {
      font-family: themes.themed('font-semi-bold', 'font-family');
      font-style: themes.themed('font-semi-bold', 'font-style');
      font-weight: themes.themed('font-semi-bold', 'font-weight');
    }

    .font-bold {
      font-family: themes.themed('font-bold', 'font-family');
      font-style: themes.themed('font-bold', 'font-style');
      font-weight: themes.themed('font-bold', 'font-weight');
    }

    .link {
      @include link-base;
      color: themes.themed('link', 'color');

      &:hover {
        color: themes.themed('link', 'color-hover');
        text-decoration-color: themes.themed('link', 'text-decoration-color');
      }
    }

    .cta-link {
      @include link-base;
      color: themes.themed('colors', 'cta-link');
      font-weight: 600;
    }
  }
}
