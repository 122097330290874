/*
 * Usage:
 *
 * html {
 *     font-family: 'Inter', sans-serif;
 * }
 *
 * @supports (font-variation-settings: normal) {
 *     html {
 *        font-family: 'Inter var', sans-serif;
 *     }
 * }
 */

/*
 * Variable font.
 */

@font-face {
    font-family: "Inter var";
    font-weight: 100 900;
    font-display: swap;
    font-style: normal;
    font-named-instance: "Regular";
    src: url("assets/fonts/inter/Inter-roman.var.woff2?v=3.19") format("woff2");
}
@font-face {
    font-family: "Inter var italic";
    font-weight: 100 900;
    font-display: swap;
    font-style: italic;
    font-named-instance: "Italic";
    src: url("assets/fonts/inter/Inter-italic.var.woff2?v=3.19") format("woff2");
}

/*
 * Non-variable font-faces for IE11 or other browsers that don't support them.
 */
@font-face {
    font-family: "Inter-Thin";
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url("assets/fonts/inter/Inter-Thin.woff2?v=3.19") format("woff2"),
        url("assets/fonts/inter/Inter-Thin.woff?v=3.19") format("woff");
}
@font-face {
    font-family: "Inter-Thin-Italic";
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url("assets/fonts/inter/Inter-ThinItalic.woff2?v=3.19") format("woff2"),
        url("assets/fonts/inter/Inter-ThinItalic.woff?v=3.19") format("woff");
}
@font-face {
    font-family: "Inter-Light-X";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url("assets/fonts/inter/Inter-ExtraLight.woff2?v=3.19") format("woff2"),
        url("assets/fonts/inter/Inter-ExtraLight.woff?v=3.19") format("woff");
}
@font-face {
    font-family: "Inter-Light-Italic-X";
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url("assets/fonts/inter/Inter-ExtraLightItalic.woff2?v=3.19") format("woff2"),
        url("assets/fonts/inter/Inter-ExtraLightItalic.woff?v=3.19") format("woff");
}
@font-face {
    font-family: "Inter-Light";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("assets/fonts/inter/Inter-Light.woff2?v=3.19") format("woff2"),
        url("assets/fonts/inter/Inter-Light.woff?v=3.19") format("woff");
}
@font-face {
    font-family: "Inter-Light-Italic";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url("assets/fonts/inter/Inter-LightItalic.woff2?v=3.19") format("woff2"),
        url("assets/fonts/inter/Inter-LightItalic.woff?v=3.19") format("woff");
}
@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("assets/fonts/inter/Inter-Regular.woff2?v=3.19") format("woff2"),
        url("assets/fonts/inter/Inter-Regular.woff?v=3.19") format("woff");
}
@font-face {
    font-family: "Inter-Italic";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("assets/fonts/inter/Inter-Italic.woff2?v=3.19") format("woff2"),
        url("assets/fonts/inter/Inter-Italic.woff?v=3.19") format("woff");
}
@font-face {
    font-family: "Inter-Medium";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("assets/fonts/inter/Inter-Medium.woff2?v=3.19") format("woff2"),
        url("assets/fonts/inter/Inter-Medium.woff?v=3.19") format("woff");
}
@font-face {
    font-family: "Inter-Medium-Italic";
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url("assets/fonts/inter/Inter-MediumItalic.woff2?v=3.19") format("woff2"),
        url("assets/fonts/inter/Inter-MediumItalic.woff?v=3.19") format("woff");
}
@font-face {
    font-family: "Inter-Semi-Bold";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("assets/fonts/inter/Inter-SemiBold.woff2?v=3.19") format("woff2"),
        url("assets/fonts/inter/Inter-SemiBold.woff?v=3.19") format("woff");
}
@font-face {
    font-family: "Inter-Semi-Bold-Italic";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url("assets/fonts/inter/Inter-SemiBoldItalic.woff2?v=3.19") format("woff2"),
        url("assets/fonts/inter/Inter-SemiBoldItalic.woff?v=3.19") format("woff");
}
@font-face {
    font-family: "Inter-Bold";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("assets/fonts/inter/Inter-Bold.woff2?v=3.19") format("woff2"),
        url("assets/fonts/inter/Inter-Bold.woff?v=3.19") format("woff");
}
@font-face {
    font-family: "Inter-Bold-Italic";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("assets/fonts/inter/Inter-BoldItalic.woff2?v=3.19") format("woff2"),
        url("assets/fonts/inter/Inter-BoldItalic.woff?v=3.19") format("woff");
}
@font-face {
    font-family: "Inter-Bold-X";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url("assets/fonts/inter/Inter-ExtraBold.woff2?v=3.19") format("woff2"),
        url("assets/fonts/inter/Inter-ExtraBold.woff?v=3.19") format("woff");
}
@font-face {
    font-family: "Inter-Bold-Italic-X";
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url("assets/fonts/inter/Inter-ExtraBoldItalic.woff2?v=3.19") format("woff2"),
        url("assets/fonts/inter/Inter-ExtraBoldItalic.woff?v=3.19") format("woff");
}
@font-face {
    font-family: "Inter-Black";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("assets/fonts/inter/Inter-Black.woff2?v=3.19") format("woff2"),
        url("assets/fonts/inter/Inter-Black.woff?v=3.19") format("woff");
}
@font-face {
    font-family: "Inter-Black-Italic";
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url("assets/fonts/inter/Inter-BlackItalic.woff2?v=3.19") format("woff2"),
        url("assets/fonts/inter/Inter-BlackItalic.woff?v=3.19") format("woff");
}

$smar-base-var-font-family: 'Inter var', 'Arial', 'Helvetica', sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol';
$smar-base-font-family: 'Inter', 'Arial', 'Helvetica', sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol';
$smar-medium-font-family: 'Inter-Medium', 'Arial', 'Helvetica', sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol';
$smar-semi-bold-font-family: 'Inter-Semi-Bold', 'Arial', 'Helvetica', sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol';
$smar-bold-font-family: 'Inter-Bold', 'Arial', 'Helvetica', sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol';
